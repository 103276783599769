import './init-webfonts';
import './init-lazy-load';
import './init-scrollbar';
import './init-search-toggle';
import './init-side-navigation';
import './init-mobile-toolbar-fix';
import './init-accordion';
import './init-datalayer-check';
import './init-footer-js';

const objectFit = document.querySelectorAll('.js-object-fit');

if (objectFit && objectFit.length) {
  import(/* webpackChunkName: "objectFit" */ './init-object-fit');
}

const carousel = document.querySelectorAll('.js-carousel');

if (carousel && carousel.length) {
  import(/* webpackChunkName: "carousel" */ './init-glide');
}

const lightbox = document.querySelectorAll('.js-btn-lightbox');

if (lightbox && lightbox.length) {
  import(/* webpackChunkName: "lightbox" */ './init-lightbox');
}

const modal = document.querySelectorAll('.js-modal');

if (modal && modal.length) {
  import(/* webpackChunkName: "modal" */ './init-modal');
}

const azElement = document.querySelectorAll('.js-az-link');

if (azElement && azElement.length) {
  import(/* webpackChunkName: "azList" */ './init-az-listing');
}

const backTopElement = document.querySelectorAll('.js-back-top');

if (backTopElement && backTopElement.length) {
  import(/* webpackChunkName: "backTopList" */ './init-back-top');
}

const mapElement = document.querySelectorAll('.js-map');

if (mapElement && mapElement.length) {
  import(/* webpackChunkName: "map" */ './init-map');
}

const secNavElement = document.querySelectorAll('.js-sec-nav-sticky');

if (secNavElement && secNavElement.length) {
  import(/* webpackChunkName: "SecondaryNav" */ './init-sec-nav-fix');
}

const cultureElement = document.querySelectorAll('.js-culture-selector-toggle');

if (cultureElement && cultureElement.length) {
  import(/* webpackChunkName: "cultureNav" */ './init-culture-toggle');
}

const officeSelectorElements = document.querySelectorAll('.js-office-selector');

if (officeSelectorElements && officeSelectorElements.length) {
  import(/* webpackChunkName: "office-selector" */ './init-office-selector');
}

const shareButtons = document.querySelectorAll('.js-share');

if (shareButtons && shareButtons.length) {
  import(/* webpackChunkName: "share-button" */ './init-share-button');
}

const datalayerForm = document.querySelectorAll('form[data-submit-datalayer]');
if (datalayerForm && datalayerForm.length) {
  import(/* webpackChunkName: "datalayerForm" */ './init-datalayer-form');
}

const bannerVideo = document.querySelectorAll('.js-banner-video');

if (bannerVideo && bannerVideo.length) {
  import(/* webpackChunkName: "bannerVideo" */ './init-banner-video');
}

const dropdownSearchForm = document.querySelectorAll('.js-form__select');

if (dropdownSearchForm && dropdownSearchForm.length) {
  import(/* webpackChunkName: "handleForm" */ './init-form-handle');
}
